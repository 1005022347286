// import 'jquery-viewport-checker';
import { TimelineLite, TweenLite, TweenMax, Linear, gsap } from "gsap";
// import ScrollMagic from "../libs/ScrollMagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";

$(function () {

	// gsap.registerPlugin(ScrollTrigger); 
	// gsap.config({nullTargetWarn:false});


	// gsap.to('.banner-item__bg', {
	// 	scrollTrigger: {
	// 		trigger: 'div[data-anchor=box_1]',
	// 		// start: 'bottom',
	// 		// markers: false,
	// 		// scrub: 1,
	// 		// start: '-=100',
	// 	},
	// 	y: -50,
	// })


	var rect = $('.container')[0].getBoundingClientRect();
	var mouse = {x: 0, y: 0, moved: false};
	
	$(".container").mousemove(function(e) {
		mouse.moved = true;
		mouse.x = e.clientX - rect.left;
		mouse.y = e.clientY - rect.top;
	});
	 
	// Ticker event will be called on every frame
	gsap.ticker.add(function(){
		if (mouse.moved){    
			parallaxIt(".bg-element--rain_1", -10);
			parallaxIt(".bg-element--rain_2",  10);
		}
		mouse.moved = false;
	});
	
	function parallaxIt(target, movement) {
		gsap.to(target, {
			duration: 0.8,
			x: (mouse.x - rect.width / 2) / rect.width * movement,
			y: (mouse.y - rect.height / 2) / rect.height * movement
		});
	}
	
	$(window).on('resize scroll', function(){
		rect = $('.container')[0].getBoundingClientRect();
	})

  var doAnimations = function() {
   
    var offset = $(window).scrollTop(),

    $animatables = $('.animatable');

    if ($animatables.length == 0) {
      $(window).off('scroll', doAnimations);
    }
    
		$animatables.each(function(i) {
       var $animatable = $(this);
			if (($animatable.offset().top) < offset) {
        $animatable.removeClass('animatable').addClass('animated');
			} else{
				$animatable.removeClass('animated').addClass('animatable');
			}
    });

	};



  
	 $(window).on('scroll', doAnimations);


});
