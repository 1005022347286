import './ui-elements'
import './form'
import './popup'
import './animation'


if($("div").is(".js-banner-slider")){ 
  var bannerslider = new Swiper('.js-banner-slider', {
    spaceBetween: 0,		
    slidesPerView: 1,
    effect: 'fade',   
    fadeEffect: {
      crossFade: true
    }, 
    // effect: "creative",
    // creativeEffect: {
    //     prev: {
    //         // translate: ['-100%',-100,-300],
    //         // rotate: [-45,-45,-45], 
    //         opacity: 1
    //     },
    //     next: {
    //         // translate: ['100%',100,-300],
    //         // rotate: [45,45,45], //([rotate around the X-axis, rotate around the Y-axis, rotate around the Z-axis])
    //         opacity: 0
    //     },
    // },  
    autoplay: {
      delay: 3000,
    },

  });
}

if($("div").is(".js-news-slider")){ 
  var product = new Swiper('.js-news-slider', {
    spaceBetween: 60,		
    slidesPerView: "auto",
    loop: true,
    navigation: {
      nextEl: '.js-news-next',
      prevEl: '.js-news-prev',
    }, 
    breakpoints: {
      1400: {
        spaceBetween: 30,		       
      },
      1200: {
        spaceBetween: 30,		
        slidesPerView: 2,       
      },
      // 768: {
      //   spaceBetween: 20,		
      //   slidesPerView: 2,
      // }, 
      768: {
        spaceBetween: 0,		
        slidesPerView: 1,
      },  
    },
    // scrollbar: {
    //   el: '.js-main-banner-scrollbar',
    //   draggable: true,
    //   snapOnRelease: true,
    //   dragSize: 'auto',
    // },
    // on: {
    //   slideChange: function (sw) {
    //     $('.box-banner .js-current').text(bannerslider.activeIndex+1);
    //   }
    // }
  });

	$('.js-nav-btn').on('click',function(){	
		var $this = $(this);
		var navBlock = $('.js-menu-wrap');
		$this.toggleClass('burger_active');
		if ($this.hasClass('burger_active')) {
				navBlock.animate({"left":"0px"});	
			$('.modal-bg').fadeIn();	
		  } else {
				navBlock.animate({"left":"-100%"});	
			$('.modal-bg').fadeOut();			
		  } 
	
	});


  if($('.home-menu').length) {
    $(".js-menu-anchor a").click(function (e) {
  
      if($(window).width()<=992){
        var navBlock = $('.js-menu-wrap');
        navBlock.animate({"left":"-100%"});	
        $('.js-nav-btn').removeClass('burger_active');
      }
      
      e.preventDefault();
      var _href = $(this).attr("href").split('#').pop();	
      console.log(_href);
      $("html, body").animate({
        scrollTop: ($("#"+_href).offset().top-100) + "px"
      });
      return false;
    });
  } 

  $(window).scroll(function(){
		var aTop = 0;
		var navblock= $('header'); 
		if($(this).scrollTop()>aTop){
			navblock.addClass('fixed');
		} else{
			navblock.removeClass('fixed');
		}
	});

  $(".js-anchor").click(function () {
    var _href = $(this).attr("href");
    $("html, body").animate({
      scrollTop: ($(_href).offset().top-100) + "px"
    });
    return false;
  });

  // Fancybox.bind("[data-fancybox]", {});
	// console.log(Fancybox);


  $('.tabs-header .tab-btn').click(function(e){
    e.preventDefault();
    $(this).closest('.tabs-box').find('.tab-btn').removeClass('active');
    $(this).addClass('active');
    let index=$(this).data('tab');
    $(this).closest('.tabs-box').find('.content-tab').removeClass('active');
    $(this).closest('.tabs-box').find('.content-tab_'+index).addClass('active');
   

    $(this).closest('.programms').find('.tabs-imgs .tabs-img').removeClass('active');
    $(this).closest('.programms').find('.tabs-img_'+index).addClass('active');

  });

  $('input[name=donation_custom]').on('focus',function(){
    $('input[name=donation]').prop('checked', false);
  });

  $('input[name=donation]').on('click',function(){
    $('input[name=donation_custom]').val('');
  });

}
